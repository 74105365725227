import { menuItems } from "../../config";
import React, { useRef } from "react";
import { navigate } from "gatsby";
import { signOut, isAuthenticated } from "../lib/auth";
import { isEmpty } from "lodash";
import {
  useMediaQuery,
  useColorModeValue,
  useColorMode,
  useDisclosure,
  Stack,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  ArrowBackIcon,
  ArrowForwardIcon,
  SunIcon,
  MoonIcon,
  SettingsIcon,
} from "@chakra-ui/icons";
import Link from "../components/elements/Link";
import Button from "../components/elements/Button";
import { isProduction } from "../lib/utils/globalVars";

function MobileMenu({ menuItems, color }) {
  const btnRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        ref={btnRef}
        aria-label="Menu Toggle"
        color="white"
        variant="link"
        fontSize="30px"
        size="lg"
        icon={<HamburgerIcon />}
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        colorScheme="blue"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody py="90px">
            <Menu menuItems={menuItems} color={color} isMobile />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

function Menu({ menuItems, color = "black", isMobile = false, ...props }) {
  // const [hideButton] = useMediaQuery([
  //   "(min-width: 1080px) and (max-width: 1280px)",
  // ]);
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Stack
      {...props}
      direction={isMobile ? "column" : "row"}
      fontSize="16px"
      spacing="30px"
      align={isMobile ? "left" : "center"}
    >
      {!isEmpty(menuItems) &&
        menuItems.map(({ label, slug }, i) => (
          <Link key={i} to={slug} color={color} fontSize="lg" fontWeight="bold">
            {label}
          </Link>
        ))}
      {!isProduction && (
        <>
          <Button
            variant="link"
            color={color}
            fontWeight="normal"
            textTransform="uppercase"
            justifyContent={isMobile ? "flex-start" : "center"}
            alignItems={isMobile ? "flex-start" : "center"}
            order={isMobile ? 99 : null}
            onClick={
              isAuthenticated()
                ? () => signOut(() => navigate(`/account/signin`))
                : () => navigate(`/account/signin`)
            }
          >
            {isAuthenticated() ? (
              <>
                {isMobile && <ArrowForwardIcon w={5} h={5} mr={3} />} Sign Out
              </>
            ) : (
              <>{isMobile && <ArrowBackIcon w={5} h={5} mr={3} />} Sign In</>
            )}
          </Button>
          {isAuthenticated() && (
            <Link
              to="/account/profile/"
              variant="link"
              color={color}
              fontWeight="normal"
              textTransform="uppercase"
            >
              <SettingsIcon w={5} h={5} mr={3} /> {isMobile && "Profile"}
            </Link>
          )}
        </>
      )}
      <Button
        variant="link"
        color={color}
        fontWeight="normal"
        textTransform="uppercase"
        justifyContent={isMobile ? "flex-start" : "center"}
        alignItems={isMobile ? "flex-start" : "center"}
        onClick={toggleColorMode}
      >
        {colorMode === "light" ? (
          <>
            <MoonIcon color="blue.500" w={5} h={5} mr={3} />{" "}
            {isMobile && "Dark Mode"}
          </>
        ) : (
          <>
            <SunIcon color="blue.500" w={5} h={5} mr={3} />{" "}
            {isMobile && "Light Mode"}
          </>
        )}
      </Button>
      {/*{!hideButton && (*/}
      {/*  <Button*/}
      {/*    as={Link}*/}
      {/*    href="https://www.movielabs.com"*/}
      {/*    target="_blank"*/}
      {/*    bgColor="blue.400"*/}
      {/*    borderRadius={5}*/}
      {/*    _hover={{*/}
      {/*      textDecoration: "none",*/}
      {/*      bgColor: "blue.600",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    More about MovieLabs*/}
      {/*  </Button>*/}
      {/*)}*/}
    </Stack>
  );
}

export default function NavBar() {
  const [isMobile] = useMediaQuery("(max-width: 1080px)");
  const color = useColorModeValue("black", "white");

  if (isMobile) {
    return <MobileMenu menuItems={menuItems} color={color} />;
  }

  return <Menu menuItems={menuItems} color="white" />;
}
