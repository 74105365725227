import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Button as ChakraButton } from "@chakra-ui/react";

const Button = ({ children, to, ...props }) => {
  if (to) {
    return (
      <ChakraButton {...props} as={GatsbyLink} to={to}>
        {children}
      </ChakraButton>
    );
  }

  return <ChakraButton {...props}>{children}</ChakraButton>;
};

export default Button;
