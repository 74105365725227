// https://github.com/dabit3/gatsby-auth-starter-aws-amplify/blob/master/src/pages/app.js

import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { navigate } from "gatsby";
import { isProduction } from "../lib/utils/globalVars";

export const isBrowser = typeof window !== "undefined";

export const getLocalUser = () =>
  isBrowser && window.localStorage.getItem("ml-session")
    ? JSON.parse(window.localStorage.getItem("ml-session"))
    : {};

export const setLocalUser = (user) => {
  window.localStorage.setItem("ml-session", JSON.stringify(user));
};

export const removeLocalUser = (user) => {
  delete window.__user;
  window.localStorage.removeItem("ml-session");
};

export function normalizeUserData(user) {
  return {
    ...user.attributes,
    username: user.username,
  };
}

export async function getUser() {
  if (typeof window !== "undefined" && window.__user) {
    // console.log(`window __user: ${window.__user}`)
    return window.__user;
  }
  try {
    const user = await Auth.currentAuthenticatedUser();
    // console.log(`Auth user: ${user}`)
    if (typeof window !== "undefined") {
      window.__user = normalizeUserData(user);
    }
    return normalizeUserData(user);
  } catch (error) {
    // console.log(error);
    // console.log(`Is Authenticated: ${isAuthenticated()}, ${window.__user}`);
    removeLocalUser(); // Mismatch between Amplify Auth and the localstate, cleanup and login
    return null;
  }
}

export function isAuthenticated() {
  if (!isBrowser) return false;
  if (isProduction) return true; // Production environment does not require sign-in

  const user = getLocalUser();
  return user ? !!user.username : false;
  // return !isProduction ? !!user.username : true;
}

export async function signIn(credentials, callback) {
  try {
    const user = await Auth.signIn(credentials);
    setLocalUser(normalizeUserData(user));
    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      return navigate(
        `/account/change-password?username=${
          user.challengeParam.userAttributes.email || credentials.username
        }`
      );
    }
    if (callback) return callback({ status: "success", ...user });
    return navigate(`/account/profile`);
  } catch (err) {
    // console.log("Error signing in..", err);
    if (callback) return callback({ status: "error", ...err });
  }
}

export function signOut(callback) {
  try {
    Auth.signOut();
    setLocalUser({});
    delete window.__user;
    if (callback) callback();
  } catch (err) {
    console.log("Error signing out..", err);
  }
}

export default function useUser({ required } = {}) {
  let [loading, setLoading] = useState(
    () => !(typeof window !== "undefined" && window.__user)
  );
  let [user, setUser] = useState(() => {
    if (typeof window === "undefined") return {};
    return window.__user || {};
  });

  useEffect(
    () => {
      let isMounted = true;

      // Signin is required when not in Production mode
      if (!isProduction) {
        // Still loading
        if (!loading && user.username) return;

        setLoading(true);

        getUser().then((_user) => {
          // Only set the user if the component is still mounted
          // console.log(`isMounted: ${isMounted}, User: ${_user}`);
          if (isMounted) {
            // When the user is not logged in but login is required
            if (required && !_user) {
              navigate(`/account/signin`);
              return;
            }
            setUser(_user);
            setLoading(false);
          }
        });
      } else {
        setUser({});
        setLoading(false);
      }

      return () => {
        isMounted = false;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { loading, user, isAuthenticated, signOut };
}
