module.exports = {
  menuItems: [
    {
      label: "Documentation",
      slug: "/docs",
    },
    {
      label: "MovieLabs Vocabulary",
      slug: "/vmc/",
    },
    {
      label: "Resources",
      slug: "/resources/overview/",
    },
  ],
  documentation: {
    pathPrefix: "/docs",
    types: [
      {
        path: "/docs/ontology",
        title: "Ontology",
        description:
          "The underlying data structures and relationships for the connected Ontologies for Media Creation",
        icon: "/icons/navbar-icons/ontology.svg",
        sources: {
          "ontology": {
            remote: `https://${process.env.GITHUB_USERNAME}:${process.env.GITHUB_PERSONAL_ACCESS_TOKEN}@github.com/${process.env.GITHUB_OWNER}/MC-Documentation-Ontology.git`,
            branch: "main"
          },
          "ontology/v2.0": {
            remote: `https://${process.env.GITHUB_USERNAME}:${process.env.GITHUB_PERSONAL_ACCESS_TOKEN}@github.com/${process.env.GITHUB_OWNER}/MC-Documentation-Ontology.git`,
            branch: "v3"
          },
          "ontology/v1.0": {
            remote: `https://${process.env.GITHUB_USERNAME}:${process.env.GITHUB_PERSONAL_ACCESS_TOKEN}@github.com/${process.env.GITHUB_OWNER}/MC-Documentation-Ontology.git`,
            branch: "v2",
          },
          // "ontology/v0": {
          //   remote: `https://${process.env.GITHUB_USERNAME}:${process.env.GITHUB_PERSONAL_ACCESS_TOKEN}@github.com/${process.env.GITHUB_OWNER}/MC-Documentation-Ontology.git`,
          //   branch: "develop",
          // },
        },
      },
      {
        path: "/docs/security",
        title: "Security",
        description:
          "Parts 1 through 5 of the Common Security Architecture for Production (CSAP)",
        icon: "/icons/navbar-icons/security.svg",
        sources: {
          security: {
            remote: `https://${process.env.GITHUB_USERNAME}:${process.env.GITHUB_PERSONAL_ACCESS_TOKEN}@github.com/${process.env.GITHUB_OWNER}/MC-Documentation-Security.git`,
            branch: "main",
          },
          "security/v1.2": {
            remote: `https://${process.env.GITHUB_USERNAME}:${process.env.GITHUB_PERSONAL_ACCESS_TOKEN}@github.com/${process.env.GITHUB_OWNER}/MC-Documentation-Security.git`,
            branch: "v3",
          },
          "security/v1.1": {
            remote: `https://${process.env.GITHUB_USERNAME}:${process.env.GITHUB_PERSONAL_ACCESS_TOKEN}@github.com/${process.env.GITHUB_OWNER}/MC-Documentation-Security.git`,
            branch: "v2",
          },
          "security/v1.0": {
            remote: `https://${process.env.GITHUB_USERNAME}:${process.env.GITHUB_PERSONAL_ACCESS_TOKEN}@github.com/${process.env.GITHUB_OWNER}/MC-Documentation-Security.git`,
            branch: "v1",
          },
          // "security/v0": {
          //   remote: `https://${process.env.GITHUB_USERNAME}:${process.env.GITHUB_PERSONAL_ACCESS_TOKEN}@github.com/${process.env.GITHUB_OWNER}/MC-Documentation-Security.git`,
          //   branch: "develop",
          // },
        },
      },
      {
        path: "/docs/visual-language",
        title: "Visual Language",
        description:
          "Designs, User Guides and Recommendations to diagram workflows or infrastructures",
        icon: "/icons/navbar-icons/visual_language.svg",
        sources: {
          "visual-language": {
            remote: `https://${process.env.GITHUB_USERNAME}:${process.env.GITHUB_PERSONAL_ACCESS_TOKEN}@github.com/${process.env.GITHUB_OWNER}/MC-Documentation-Vizlang.git`,
            branch: "main",
          },
          "visual-language/v1.1": {
            remote: `https://${process.env.GITHUB_USERNAME}:${process.env.GITHUB_PERSONAL_ACCESS_TOKEN}@github.com/${process.env.GITHUB_OWNER}/MC-Documentation-Vizlang.git`,
            branch: "v2",
          },
          "visual-language/v1.0": {
            remote: `https://${process.env.GITHUB_USERNAME}:${process.env.GITHUB_PERSONAL_ACCESS_TOKEN}@github.com/${process.env.GITHUB_OWNER}/MC-Documentation-Vizlang.git`,
            branch: "v1",
          },
          // "visual-language/v0": {
          //   remote: `https://${process.env.GITHUB_USERNAME}:${process.env.GITHUB_PERSONAL_ACCESS_TOKEN}@github.com/${process.env.GITHUB_OWNER}/MC-Documentation-Vizlang.git`,
          //   branch: "develop",
          // },
        },
      },
      {
        path: "/docs/file-naming",
        title: "File Naming",
        description: "",
        icon: "/icons/navbar-icons/document_icon.png",
        sources: {},
      },
      {
        path: "/docs/software-defined-workflows",
        title: "Software Defined Workflows",
        description: "",
        icon: "/icons/navbar-icons/workflow_icon.png",
        sources: {},
      },
    ],
  },
  vocabulary: {
    pathPrefix: "/vmc",
    types: [
      {
        path: "/vmc",
        title: "Vocabulary",
        description:
          "A glossary of terms associated with the Ontology including their iconography from the Visual Language",
        icon: "/icons/navbar-icons/vocabulary.svg",
      },
    ],
  },
  resources: {
    pathPrefix: "",
    types: [
      {
        path: "/resources",
        title: "Resources",
        description: "",
        icon: "",
        sources: {
          resources: {
            remote: `https://${process.env.GITHUB_USERNAME}:${process.env.GITHUB_PERSONAL_ACCESS_TOKEN}@github.com/MovieLabs/Prodtech-MMC-Documentation.git`,
            branch: "main",
          },
        },
      },
    ],
  },
  visualLanguageIcons: {
    "s-Asset": {
      id: "s-Asset",
      label: "Asset",
      name: "asset",
      image: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Asset.svg",
      download: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Asset.svg",
    },
    "c-000037": {
      id: "c-000037",
      label: "Analog Asset",
      name: "asset",
      image: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Asset.svg",
      download: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Asset.svg",
    },
    "c-000034": {
      id: "c-000034",
      label: "Asset",
      name: "asset",
      image: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Asset.svg",
      download: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Asset.svg",
    },
    "c-00004D": {
      id: "c-00004D",
      label: "Asset Functional Class",
      name: "",
      image: "",
      download: "",
    },
    "c-00035B": {
      id: "c-00035B",
      label: "Asset Group",
      name: "",
      image: "",
      download: "",
    },
    "c-000035": {
      id: "c-000035",
      label: "Asset Structural Class",
      name: "",
      image: "",
      download: "",
    },
    "c-000042": {
      id: "c-000042",
      label: "Analog Audio",
      name: "audio",
      image: "/vislang/Icons/SVG/Assets/audio.svg",
      download: "/vislang/Icons/SVG/Assets/audio.svg",
    },
    "c-000045": {
      id: "c-000045",
      label: "Analog Audio-Visual",
      name: "audio_visual",
      image: "/vislang/Icons/SVG/Assets/audio_visual.svg",
      download: "/vislang/Icons/SVG/Assets/audio_visual.svg",
    },
    "c-000041": {
      id: "c-000041",
      label: "Audio",
      name: "audio",
      image: "/vislang/Icons/SVG/Assets/audio.svg",
      download: "/vislang/Icons/SVG/Assets/audio.svg",
    },
    "c-000377": {
      id: "c-000377",
      label: "Audiovisual",
      name: "audio_visual",
      image: "/vislang/Icons/SVG/Assets/audio_visual.svg",
      download: "/vislang/Icons/SVG/Assets/audio_visual.svg",
    },
    "c-000043": {
      id: "c-000043",
      label: "Digital Audio",
      name: "audio",
      image: "/vislang/Icons/SVG/Assets/audio.svg",
      download: "/vislang/Icons/SVG/Assets/audio.svg",
    },
    "c-000046": {
      id: "c-000046",
      label: "Digital Audio-Visual",
      name: "audio_visual",
      image: "/vislang/Icons/SVG/Assets/audio_visual.svg",
      download: "/vislang/Icons/SVG/Assets/audio_visual.svg",
    },
    "c-00003A": {
      id: "c-00003A",
      label: "Digital Data",
      name: "document",
      image: "/vislang/Icons/SVG/Assets/document.svg",
      download: "/vislang/Icons/SVG/Assets/document.svg",
    },
    "c-000049": {
      id: "c-000049",
      label: "Digital Document",
      name: "document",
      image: "/vislang/Icons/SVG/Assets/document.svg",
      download: "/vislang/Icons/SVG/Assets/document.svg",
    },
    "c-00003D": {
      id: "c-00003D",
      label: "Digital Image",
      name: "image",
      image: "/vislang/Icons/SVG/Assets/image.svg",
      download: "/vislang/Icons/SVG/Assets/image.svg",
    },
    "c-000040": {
      id: "c-000040",
      label: "Digital Moving Image",
      name: "moving_image",
      image: "/vislang/Icons/SVG/Assets/moving_image.svg",
      download: "/vislang/Icons/SVG/Assets/moving_image.svg",
    },
    "c-00004C": {
      id: "c-00004C",
      label: "Digital Structured Document",
      name: "structured_document",
      image: "/vislang/Icons/SVG/Assets/structured_document.svg",
      download: "/vislang/Icons/SVG/Assets/structured_document.svg",
    },
    "c-000047": {
      id: "c-000047",
      label: "Document",
      name: "document",
      image: "/vislang/Icons/SVG/Assets/document.svg",
      download: "/vislang/Icons/SVG/Assets/document.svg",
    },
    "c-00003B": {
      id: "c-00003B",
      label: "Image",
      name: "image",
      image: "/vislang/Icons/SVG/Assets/image.svg",
      download: "/vislang/Icons/SVG/Assets/image.svg",
    },
    "c-00035A": {
      id: "c-00035A",
      label: "Image Sequence",
      name: "sequence",
      image: "/vislang/Icons/SVG/Assets/moving_image.svg",
      download: "/vislang/Icons/Assets/moving_image.svg",
    },
    "c-00003E": {
      id: "c-00003E",
      label: "Moving Image",
      name: "moving_image",
      image: "/vislang/Icons/SVG/Assets/moving_image.svg",
      download: "/vislang/Icons/SVG/Assets/moving_image.svg",
    },
    "c-000048": {
      id: "c-000048",
      label: "Physical Document",
      name: "document",
      image: "/vislang/Icons/SVG/Assets/document.svg",
      download: "/vislang/Icons/SVG/Assets/document.svg",
    },
    "c-00003C": {
      id: "c-00003C",
      label: "Physical Image",
      name: "image",
      image: "/vislang/Icons/SVG/Assets/image.svg",
      download: "/vislang/Icons/SVG/Assets/image.svg",
    },
    "c-000376": {
      id: "c-000376",
      label: "Physical Moving Image",
      name: "moving_image",
      image: "/vislang/Icons/SVG/Assets/moving_image.svg",
      download: "/vislang/Icons/SVG/Assets/moving_image.svg",
    },
    "c-00004B": {
      id: "c-00004B",
      label: "Physical Structured Document",
      name: "structured_document",
      image: "/vislang/Icons/SVG/Assets/structured_document.svg",
      download: "/vislang/Icons/SVG/Assets/structured_document.svg",
    },
    "c-00004A": {
      id: "c-00004A",
      label: "Structured Document",
      name: "structured_document",
      image: "/vislang/Icons/SVG/Assets/structured_document.svg",
      download: "/vislang/Icons/SVG/Assets/structured_document.svg",
    },
    "c-000357": {
      id: "c-000357",
      label: "Capture",
      name: "",
      image: "",
      download: "",
    },
    "c-00000D": {
      id: "c-00000D",
      label: "Costume",
      name: "costume",
      image: "/vislang/Icons/SVG/Context/costume.svg",
      download: "/vislang/Icons/SVG/Context/costume.svg",
    },
    "c-000010": {
      id: "c-000010",
      label: "Creative Reference Material",
      name: "reference_material",
      image: "/vislang/Icons/SVG/Context/reference_material.svg",
      download: "/vislang/Icons/SVG/Context/reference_material.svg",
    },
    "c-000038": {
      id: "c-000038",
      label: "Digital Asset",
      name: "asset",
      image: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Asset.svg",
      download: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Asset.svg",
    },
    "c-000036": {
      id: "c-000036",
      label: "Physical Asset",
      name: "asset",
      image: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Asset.svg",
      download: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Asset.svg",
    },
    "c-000013": {
      id: "c-000013",
      label: "Production Set",
      name: "set",
      image: "/vislang/Icons/SVG/Context/set.svg",
      download: "/vislang/Icons/SVG/Context/set.svg",
    },
    "c-000356": {
      id: "c-000356",
      label: "Script",
      name: "script",
      image: "/vislang/Icons/SVG/Context/script.svg",
      download: "/vislang/Icons/SVG/Context/script.svg",
    },
    "c-00002C": {
      id: "c-00002C",
      label: "Sequence",
      name: "sequence",
      image: "/vislang/Icons/SVG/Context/sequence.svg",
      download: "/vislang/Icons/SVG/Context/sequence.svg",
    },
    "c-000031": {
      id: "c-000031",
      label: "Animation Sequence",
      name: "sequence",
      image: "/vislang/Icons/SVG/Context/sequence.svg",
      download: "/vislang/Icons/SVG/Context/sequence.svg",
    },
    "c-000032": {
      id: "c-000032",
      label: "Color Sequence",
      name: "sequence",
      image: "/vislang/Icons/SVG/Context/sequence.svg",
      download: "/vislang/Icons/SVG/Context/sequence.svg",
    },
    "c-00002D": {
      id: "c-00002D",
      label: "Editorial Sequence",
      name: "sequence",
      image: "/vislang/Icons/SVG/Context/sequence.svg",
      download: "/vislang/Icons/SVG/Context/sequence.svg",
    },
    "c-00034C": {
      id: "c-00034C",
      label: "Sequence Choronolgy Descriptor",
      name: "",
      image: "",
      download: "",
    },
    "c-000359": {
      id: "c-000359",
      label: "VFX Image Sequence",
      name: "sequence",
      image: "/vislang/Icons/SVG/Context/sequence.svg",
      download: "/vislang/Icons/SVG/Context/sequence.svg",
    },
    "c-00002F": {
      id: "c-00002F",
      label: "VFX Sequence",
      name: "sequence",
      image: "/vislang/Icons/SVG/Context/sequence.svg",
      download: "/vislang/Icons/SVG/Context/sequence.svg",
    },
    "c-00000B": {
      id: "c-00000B",
      label: "Set Dressing",
      name: "prop",
      image: "/vislang/Icons/SVG/Context/prop.svg",
      download: "/vislang/Icons/SVG/Context/prop.svg",
    },
    "c-00034F": {
      id: "c-00034F",
      label: "Shot",
      name: "shot",
      image: "/vislang/Icons/SVG/Context/shot.svg",
      download: "/vislang/Icons/SVG/Context/shot.svg",
    },
    "c-000030": {
      id: "c-000030",
      label: "Animation Shot",
      name: "shot",
      image: "/vislang/Icons/SVG/Context/shot.svg",
      download: "/vislang/Icons/SVG/Context/shot.svg",
    },
    "c-00002E": {
      id: "c-00002E",
      label: "VFX Shot",
      name: "shot",
      image: "/vislang/Icons/SVG/Context/shot.svg",
      download: "/vislang/Icons/SVG/Context/shot.svg",
    },
    "c-00000E": {
      id: "c-00000E",
      label: "Storyboard",
      name: "storyboard",
      image: "/vislang/Icons/SVG/Context/storyboard.svg",
      download: "/vislang/Icons/SVG/Context/storyboard.svg",
    },
    "c-00000F": {
      id: "c-00000F",
      label: "Animated Storyboard",
      name: "storyboard",
      image: "/vislang/Icons/SVG/Context/storyboard.svg",
      download: "/vislang/Icons/SVG/Context/storyboard.svg",
    },
    "c-000011": {
      id: "c-000011",
      label: "Technical Reference Material",
      name: "reference_material",
      image: "/vislang/Icons/SVG/Context/reference_material.svg",
      download: "/vislang/Icons/SVG/Context/reference_material.svg",
    },
    "s-Camera": {
      id: "s-Camera",
      label: "Camera",
      name: "camera",
      image: "/vislang/Icons/SVG/Infrastructure/camera.svg",
      download: "/vislang/Icons/SVG/Infrastructure/camera.svg",
    },
    "c-00005D": {
      id: "c-00005D",
      label: "Camera",
      name: "camera",
      image: "/vislang/Icons/SVG/Infrastructure/camera.svg",
      download: "/vislang/Icons/SVG/Assets/Infrastructure.svg",
    },
    "c-000073": {
      id: "c-000073",
      label: "Camera Firmware Version",
      name: "",
      image: "",
      download: "",
    },
    "c-00006F": {
      id: "c-00006F",
      label: "Camera Make",
      name: "",
      image: "",
      download: "",
    },
    "c-000070": {
      id: "c-000070",
      label: "Camera Model",
      name: "",
      image: "",
      download: "",
    },
    "c-000072": {
      id: "c-000072",
      label: "Camera Serial Number",
      name: "",
      image: "",
      download: "",
    },
    "c-000071": {
      id: "c-000071",
      label: "Camera UID",
      name: "",
      image: "",
      download: "",
    },
    "c-00006D": {
      id: "c-00006D",
      label: "Exposure Index",
      name: "",
      image: "",
      download: "",
    },
    "c-000069": {
      id: "c-000069",
      label: "f-Stop",
      name: "",
      image: "",
      download: "",
    },
    "c-000078": {
      id: "c-000078",
      label: "Flip-X",
      name: "",
      image: "",
      download: "",
    },
    "c-000079": {
      id: "c-000079",
      label: "Flip-Y",
      name: "",
      image: "",
      download: "",
    },
    "c-000075": {
      id: "c-000075",
      label: "Frame Height",
      name: "",
      image: "",
      download: "",
    },
    "c-000076": {
      id: "c-000076",
      label: "Frame Width",
      name: "",
      image: "",
      download: "",
    },
    "c-000068": {
      id: "c-000068",
      label: "ISO",
      name: "",
      image: "",
      download: "",
    },
    "c-00007A": {
      id: "c-00007A",
      label: "LUT UID",
      name: "",
      image: "",
      download: "",
    },
    "c-000066": {
      id: "c-000066",
      label: "Mag",
      name: "",
      image: "",
      download: "",
    },
    "c-000077": {
      id: "c-000077",
      label: "Pixel Aspect Ratio",
      name: "",
      image: "",
      download: "",
    },
    "c-000067": {
      id: "c-000067",
      label: "Reel",
      name: "",
      image: "",
      download: "",
    },
    "c-00006E": {
      id: "c-00006E",
      label: "Reel Name",
      name: "",
      image: "",
      download: "",
    },
    "c-000080": {
      id: "c-000080",
      label: "Roll",
      name: "",
      image: "",
      download: "",
    },
    "c-00007D": {
      id: "c-00007D",
      label: "Saturation",
      name: "",
      image: "",
      download: "",
    },
    "c-00006B": {
      id: "c-00006B",
      label: "Shutter Angle",
      name: "",
      image: "",
      download: "",
    },
    "c-00007E": {
      id: "c-00007E",
      label: "SOP",
      name: "",
      image: "",
      download: "",
    },
    "c-00006A": {
      id: "c-00006A",
      label: "T-Stop",
      name: "",
      image: "",
      download: "",
    },
    "c-00007F": {
      id: "c-00007F",
      label: "Tilt",
      name: "",
      image: "",
      download: "",
    },
    "c-00007B": {
      id: "c-00007B",
      label: "Tint",
      name: "",
      image: "",
      download: "",
    },
    "c-00007C": {
      id: "c-00007C",
      label: "White Balance",
      name: "",
      image: "",
      download: "",
    },
    "c-00038D": {
      id: "c-00038D",
      label: "Circle Take",
      name: "",
      image: "",
      download: "",
    },
    "c-000060": {
      id: "c-000060",
      label: "Frames Per Second",
      name: "",
      image: "",
      download: "",
    },
    "c-000062": {
      id: "c-000062",
      label: "Playback FPS",
      name: "",
      image: "",
      download: "",
    },
    "c-000061": {
      id: "c-000061",
      label: "Recording FPS",
      name: "",
      image: "",
      download: "",
    },
    "c-000081": {
      id: "c-000081",
      label: "Lens",
      name: "",
      image: "",
      download: "",
    },
    "c-00038c": {
      id: "c-00038c",
      label: "Aperture",
      name: "",
      image: "",
      download: "",
    },
    "c-000083": {
      id: "c-000083",
      label: "Focal Length",
      name: "",
      image: "",
      download: "",
    },
    "c-000082": {
      id: "c-000082",
      label: "Focus",
      name: "",
      image: "",
      download: "",
    },
    "c-000084": {
      id: "c-000084",
      label: "Lens Make",
      name: "",
      image: "",
      download: "",
    },
    "c-000085": {
      id: "c-000085",
      label: "Lens Model",
      name: "",
      image: "",
      download: "",
    },
    "c-000086": {
      id: "c-000086",
      label: "Lens Serial Number",
      name: "",
      image: "",
      download: "",
    },
    "c-00005E": {
      id: "c-00005E",
      label: "Look up table",
      name: "",
      image: "",
      download: "",
    },
    "c-000088": {
      id: "c-000088",
      label: "Recorder",
      name: "",
      image: "",
      download: "",
    },
    "c-000089": {
      id: "c-000089",
      label: "Recorder Firmware Version",
      name: "",
      image: "",
      download: "",
    },
    "c-00008A": {
      id: "c-00008A",
      label: "Recorder Make",
      name: "",
      image: "",
      download: "",
    },
    "c-00008B": {
      id: "c-00008B",
      label: "Recorder Model",
      name: "",
      image: "",
      download: "",
    },
    "c-00008C": {
      id: "c-00008C",
      label: "Recorder Serial Number",
      name: "",
      image: "",
      download: "",
    },
    "c-00008D": {
      id: "c-00008D",
      label: "Storage Media UID",
      name: "",
      image: "",
      download: "",
    },
    "c-000063": {
      id: "c-000063",
      label: "Timecode",
      name: "",
      image: "",
      download: "",
    },
    "c-000065": {
      id: "c-000065",
      label: "Timecode - end",
      name: "",
      image: "",
      download: "",
    },
    "c-000064": {
      id: "c-000064",
      label: "Timecode - start",
      name: "",
      image: "",
      download: "",
    },
    "s-Creative-Work": {
      id: "s-Creative-Work",
      label: "Creative Work",
      name: "creative_work",
      image: "/vislang/Icons/SVG/Context/creative_work.svg",
      download: "/vislang/Icons/SVG/Context/creative_work.svg",
    },
    "c-000390": {
      id: "c-000390",
      label: "Based On",
      name: "reference_material",
      image: "/vislang/Icons/SVG/Context/reference_material.svg",
      download: "/vislang/Icons/SVG/Context/reference_material.svg",
    },
    "c-00005B": {
      id: "c-00005B",
      label: "Contribution",
      name: "",
      image: "",
      download: "",
    },
    "c-000392": {
      id: "c-000392",
      label: "Country of Origin",
      name: "",
      image: "",
      download: "",
    },
    "c-00001F": {
      id: "c-00001F",
      label: "Creative Work",
      name: "creative_work",
      image: "/vislang/Icons/SVG/Context/creative_work.svg",
      download: "/vislang/Icons/SVG/Context/creative_work.svg",
    },
    "c-00038F": {
      id: "c-00038F",
      label: "Creative Work Category",
      name: "",
      image: "",
      download: "",
    },
    "c-00038E": {
      id: "c-00038E",
      label: "Creative Work Title",
      name: "",
      image: "",
      download: "",
    },
    "c-000391": {
      id: "c-000391",
      label: "Inspired By",
      name: "reference_material",
      image: "/vislang/Icons/SVG/Context/reference_material.svg",
      download: "/vislang/Icons/SVG/Context/reference_material.svg",
    },
    "c-000393": {
      id: "c-000393",
      label: "Production Company",
      name: "organization",
      image: "/vislang/Icons/SVG/Participants/organization.svg",
      download: "/vislang/Icons/SVG/Participants/organization.svg",
    },
    "c-000394": {
      id: "c-000394",
      label: "Related Work",
      name: "reference_material",
      image: "/vislang/Icons/SVG/Context/reference_material.svg",
      download: "/vislang/Icons/SVG/Context/reference_material.svg",
    },
    "s-Infrastructure": {
      id: "s-Infrastructure",
      label: "Infrastructure",
      name: "infrastructure",
      image: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Infrastructure.svg",
      download:
        "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Infrastructure.svg",
    },
    "c-0003A8": {
      id: "c-0003A8",
      label: "Artifical Intelligence",
      name: "artificial_intelligence",
      image: "/vislang/Icons/SVG/Infrastructure/artificial_intelligence.svg",
      download: "/vislang/Icons/SVG/Infrastructure/artificial_intelligence.svg",
    },
    "c-000095": {
      id: "c-000095",
      label: "Cloud",
      name: "cloud",
      image: "/vislang/Icons/SVG/Infrastructure/cloud.svg",
      download: "/vislang/Icons/SVG/Infrastructure/cloud.svg",
    },
    "c-00039A": {
      id: "c-00039A",
      label: "Private Cloud",
      name: "cloud",
      image: "/vislang/Icons/SVG/Infrastructure/cloud.svg",
      download: "/vislang/Icons/SVG/Infrastructure/cloud.svg",
    },
    "c-000399": {
      id: "c-000399",
      label: "Public Cloud",
      name: "cloud",
      image: "/vislang/Icons/SVG/Infrastructure/cloud.svg",
      download: "/vislang/Icons/SVG/Infrastructure/cloud.svg",
    },
    "c-00039B": {
      id: "c-00039B",
      label: "Compute Resource",
      name: "compute",
      image: "/vislang/Icons/SVG/Infrastructure/compute.svg",
      download: "/vislang/Icons/SVG/Infrastructure/compute.svg",
    },
    "c-0000A0": {
      id: "c-0000A0",
      label: "Remote Desktop",
      name: "workstation_MS",
      image: "/vislang/Icons/SVG/Infrastructure/workstation_MS.svg",
      download: "/vislang/Icons/SVG/Infrastructure/workstation_MS.svg",
    },
    "c-00009D": {
      id: "c-00009D",
      label: "Workstation",
      name: "workstation_MS",
      image: "/vislang/Icons/SVG/Infrastructure/workstation_MS.svg",
      download: "/vislang/Icons/SVG/Infrastructure/workstation_MS.svg",
    },
    "c-0000B3": {
      id: "c-0000B3",
      label: "Edge Compute",
      name: "",
      image: "",
      download: "",
    },
    "c-0003A0": {
      id: "c-0003A0",
      label: "Edge Storage",
      name: "",
      image: "",
      download: "",
    },
    "c-0003AB": {
      id: "c-0003AB",
      label: "Electronic Mail System",
      name: "electronic_mail_system",
      image: "/vislang/Icons/SVG/Infrastructure/electronic_mail_system.svg",
      download: "/vislang/Icons/SVG/Infrastructure/electronic_mail_system.svg",
    },
    "c-00008F": {
      id: "c-00008F",
      label: "Infrastucture",
      name: "infrastructure",
      image: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Infrastructure.svg",
      download:
        "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Infrastructure.svg",
    },
    "c-0000CE": {
      id: "c-0000CE",
      label: "Machine Learning",
      name: "machine_learning",
      image: "/vislang/Icons/SVG/Infrastructure/machine_learning.svg",
      download: "/vislang/Icons/SVG/Infrastructure/machine_learning.svg",
    },
    "c-0000B8": {
      id: "c-0000B8",
      label: "Messenging System",
      name: "messenging_system",
      image: "/vislang/Icons/SVG/Infrastructure/messaging_system.svg",
      download: "/vislang/Icons/SVG/Infrastructure/messaging_system.svg",
    },
    "c-0000BA": {
      id: "c-0000BA",
      label: "Message",
      name: "",
      image: "",
      download: "",
    },
    "c-0000BB": {
      id: "c-0000BB",
      label: "Message Bus",
      name: "",
      image: "",
      download: "",
    },
    "c-0000C2": {
      id: "c-0000C2",
      label: "Event Queue",
      name: "",
      image: "",
      download: "",
    },
    "c-0000BC": {
      id: "c-0000BC",
      label: "Message Broker",
      name: "",
      image: "",
      download: "",
    },
    "c-0000BE": {
      id: "c-0000BE",
      label: "Message Queue",
      name: "",
      image: "",
      download: "",
    },
    "c-0000C0": {
      id: "c-0000C0",
      label: "Message Consumer",
      name: "",
      image: "",
      download: "",
    },
    "c-0000BF": {
      id: "c-0000BF",
      label: "Message Producer",
      name: "",
      image: "",
      download: "",
    },
    "c-0000C9": {
      id: "c-0000C9",
      label: "Microservice",
      name: "microservice",
      image: "/vislang/Icons/SVG/Infrastructure/microservice.svg",
      download: "/vislang/Icons/SVG/Infrastructure/microservice.svg",
    },
    "c-0000A5": {
      id: "c-0000A5",
      label: "Network",
      name: "network",
      image: "/vislang/Icons/SVG/Infrastructure/network_infrastructure.svg",
      download: "/vislang/Icons/SVG/Infrastructure/network_infrastructure.svg",
    },
    "c-00039C": {
      id: "c-00039C",
      label: "Cellular Network",
      name: "cellular_network",
      image: "/vislang/Icons/SVG/Infrastructure/cellular_network.svg",
      download: "/vislang/Icons/SVG/Infrastructure/cellular_network.svg",
    },
    "c-00039E": {
      id: "c-00039E",
      label: "Ethernet Network",
      name: "ethernet_network",
      image: "/vislang/Icons/SVG/Infrastructure/ethernet_network.svg",
      download: "/vislang/Icons/SVG/Infrastructure/ethernet_network.svg",
    },
    "c-0003AA": {
      id: "c-0003AA",
      label: "Fiber Optic Network",
      name: "fiber_optic_network",
      image: "/vislang/Icons/SVG/Infrastructure/fiber_optic_network.svg",
      download: "/vislang/Icons/SVG/Infrastructure/fiber_optic_network.svg",
    },
    "c-0000A6": {
      id: "c-0000A6",
      label: "Private Network",
      name: "network",
      image: "/vislang/Icons/SVG/Infrastructure/network_infrastructure.svg",
      download: "/vislang/Icons/SVG/Infrastructure/network_infrastructure.svg",
    },
    "c-0000A7": {
      id: "c-0000A7",
      label: "Public Network",
      name: "network",
      image: "/vislang/Icons/SVG/Infrastructure/network_infrastructure.svg",
      download: "/vislang/Icons/SVG/Infrastructure/network_infrastructure.svg",
    },
    "c-00039D": {
      id: "c-00039D",
      label: "Wi-Fi Network",
      name: "wifi",
      image: "/vislang/Icons/SVG/Infrastructure/wifi.svg",
      download: "/vislang/Icons/SVG/Infrastructure/wifi.svg",
    },
    "c-000093": {
      id: "c-000093",
      label: "Orchestration",
      name: "orchestration",
      image: "/vislang/Icons/SVG/Infrastructure/orchestration.svg",
      download: "/vislang/Icons/SVG/Infrastructure/orchestration.svg",
    },
    "c-000094": {
      id: "c-000094",
      label: "Orchestration Manager",
      name: "orchestration_manager",
      image: "/vislang/Icons/SVG/Infrastructure/orchestration_manager.svg",
      download: "/vislang/Icons/SVG/Infrastructure/orchestration_manager.svg",
    },
    "c-0003A3": {
      id: "c-0003A3",
      label: "Production Consumable",
      name: "production_consumables",
      image: "/vislang/Icons/SVG/Infrastructure/production_consumables.svg",
      download: "/vislang/Icons/SVG/Infrastructure/production_consumables.svg",
    },
    "c-0003A2": {
      id: "c-0003A2",
      label: "Producation Equipment",
      name: "production_equipment",
      image: "/vislang/Icons/SVG/Infrastructure/production_equipment.svg",
      download: "/vislang/Icons/SVG/Infrastructure/production_equipment.svg",
    },
    "c-0000C8": {
      id: "c-0000C8",
      label: "Remote Procedural Call",
      name: "",
      image: "",
      download: "",
    },
    "c-0000CD": {
      id: "c-0000CD",
      label: "Software Application",
      name: "software_application",
      image: "/vislang/Icons/SVG/Infrastructure/software_application.svg",
      download: "/vislang/Icons/SVG/Infrastructure/software_application.svg",
    },
    "c-000090": {
      id: "c-000090",
      label: "Software-Defined Workflow",
      name: "",
      image: "",
      download: "",
    },
    "c-0003A1": {
      id: "c-0003A1",
      label: "Storage",
      name: "storage_MS",
      image: "/vislang/Icons/SVG/Infrastructure/storage_MS.svg",
      download: "/vislang/Icons/SVG/Infrastructure/storage_MS.svg",
    },
    "c-0003A4": {
      id: "c-0003A4",
      label: "Capture Storage",
      name: "capture_storage",
      image: "/vislang/Icons/SVG/Infrastructure/capture_storage.svg",
      download: "/vislang/Icons/SVG/Infrastructure/capture_storage.svg",
    },
    "c-0000CB": {
      id: "c-0000CB",
      label: "Database",
      name: "database",
      image: "/vislang/Icons/SVG/Infrastructure/database.svg",
      download: "/vislang/Icons/SVG/Infrastructure/database.svg",
    },
    "c-0003A7": {
      id: "c-0003A7",
      label: "Long Term Storage",
      name: "long_term_storage",
      image: "/vislang/Icons/SVG/Infrastructure/long_term_storage.svg",
      download: "/vislang/Icons/SVG/Infrastructure/long_term_storage.svg",
    },
    "c-0003A5": {
      id: "c-0003A5",
      label: "Transient Storage",
      name: "transient_storage",
      image: "/vislang/Icons/SVG/Infrastructure/transient_storage.svg",
      download: "/vislang/Icons/SVG/Infrastructure/transient_storage.svg",
    },
    "c-0003A6": {
      id: "c-0003A6",
      label: "Working Storage",
      name: "working_storage",
      image: "/vislang/Icons/SVG/Infrastructure/working_storage.svg",
      download: "/vislang/Icons/SVG/Infrastructure/working_storage.svg",
    },
    "c-000398": {
      id: "c-000398",
      label: "Workflow Manager",
      name: "workflow_manager",
      image: "/vislang/Icons/SVG/Infrastructure/workflow_manager.svg",
      download: "/vislang/Icons/SVG/Infrastructure/workflow_manager.svg",
    },
    "s-Media-Creation-Context": {
      id: "s-Media-Creation-Context",
      label: "Media Creation Context",
      name: "context",
      image: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Context.svg",
      download: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Context.svg",
    },
    "c-00001A": {
      id: "c-00001A",
      label: "Character",
      name: "character",
      image: "/vislang/Icons/SVG/Context/character.svg",
      download: "/vislang/Icons/SVG/Context/character.svg",
    },
    "c-00001B": {
      id: "c-00001B",
      label: "Concept",
      name: "concept",
      image: "/vislang/Icons/SVG/Context/concept.svg",
      download: "/vislang/Icons/SVG/Context/concept.svg",
    },
    "c-00001C": {
      id: "c-00001C",
      label: "Concept Art",
      name: "concept",
      image: "/vislang/Icons/SVG/Context/concept.svg",
      download: "/vislang/Icons/SVG/Context/concept.svg",
    },
    "c-00001D": {
      id: "c-00001D",
      label: "Depiction",
      name: "depiction",
      image: "/vislang/Icons/SVG/Context/depiction.svg",
      download: "/vislang/Icons/SVG/Context/depiction.svg",
    },
    "c-00001E": {
      id: "c-00001E",
      label: "Portrayal",
      name: "depiction",
      image: "/vislang/Icons/SVG/Context/depiction.svg",
      download: "/vislang/Icons/SVG/Context/depiction.svg",
    },
    "c-000020": {
      id: "c-000020",
      label: "Identifier",
      name: "",
      image: "",
      download: "",
    },
    "c-000005": {
      id: "c-000005",
      label: "Location",
      name: "location",
      image: "/vislang/Icons/SVG/Context/location.svg",
      download: "/vislang/Icons/SVG/Context/location.svg",
    },
    "c-000006": {
      id: "c-000006",
      label: "Narrative Location",
      name: "location",
      image: "/vislang/Icons/SVG/Context/location.svg",
      download: "/vislang/Icons/SVG/Context/location.svg",
    },
    "c-000007": {
      id: "c-000007",
      label: "Shooting Location",
      name: "location",
      image: "/vislang/Icons/SVG/Context/location.svg",
      download: "/vislang/Icons/SVG/Context/location.svg",
    },
    "c-000002": {
      id: "c-000002",
      label: "Media Creation Context",
      name: "",
      image: "",
      download: "",
    },
    "c-000003": {
      id: "c-000003",
      label: "Narrative Context",
      name: "",
      image: "",
      download: "",
    },
    "c-000004": {
      id: "c-000004",
      label: "Production Context",
      name: "",
      image: "",
      download: "",
    },
    "c-00000C": {
      id: "c-00000C",
      label: "Narrative Wardrobe",
      name: "costume",
      image: "/vislang/Icons/SVG/Context/costume.svg",
      download: "/vislang/Icons/SVG/Context/costume.svg",
    },
    "c-000008": {
      id: "c-000008",
      label: "Prop",
      name: "prop",
      image: "/vislang/Icons/SVG/Context/prop.svg",
      download: "/vislang/Icons/SVG/Context/prop.svg",
    },
    "c-000009": {
      id: "c-000009",
      label: "Narrative Prop",
      name: "prop",
      image: "/vislang/Icons/SVG/Context/prop.svg",
      download: "/vislang/Icons/SVG/Context/prop.svg",
    },
    "c-00000A": {
      id: "c-00000A",
      label: "Production Prop",
      name: "prop",
      image: "/vislang/Icons/SVG/Context/prop.svg",
      download: "/vislang/Icons/SVG/Context/prop.svg",
    },
    "c-000014": {
      id: "c-000014",
      label: "Scene",
      name: "narrative_scene",
      image: "/vislang/Icons/SVG/Context/narrative_scene.svg",
      download: "/vislang/Icons/SVG/Context/narrative_scene.svg",
    },
    "c-000015": {
      id: "c-000015",
      label: "Narrative Scene",
      name: "narrative_scene",
      image: "/vislang/Icons/SVG/Context/narrative_scene.svg",
      download: "/vislang/Icons/SVG/Context/narrative_scene.svg",
    },
    "c-000016": {
      id: "c-000016",
      label: "Production Scene",
      name: "production_scene",
      image: "/vislang/Icons/SVG/Context/production_scene.svg",
      download: "/vislang/Icons/SVG/Context/production_scene.svg",
    },
    "c-000019": {
      id: "c-000019",
      label: "Scene Header",
      name: "narrative_scene",
      image: "/vislang/Icons/SVG/Context/narrative_scene.svg",
      download: "/vislang/Icons/SVG/Context/narrative_scene.svg",
    },
    "c-000018": {
      id: "c-000018",
      label: "Scene Number",
      name: "narrative_scene",
      image: "/vislang/Icons/SVG/Context/narrative_scene.svg",
      download: "/vislang/Icons/SVG/Context/narrative_scene.svg",
    },
    "c-000012": {
      id: "c-000012",
      label: "Shooting Set",
      name: "set",
      image: "/vislang/Icons/SVG/Context/set.svg",
      download: "/vislang/Icons/SVG/Context/set.svg",
    },
    "c-000021": {
      id: "c-000021",
      label: "Slate",
      name: "slate",
      image: "/vislang/Icons/SVG/Context/slate.svg",
      download: "/vislang/Icons/SVG/Context/slate.svg",
    },
    "c-000026": {
      id: "c-000026",
      label: "Camera Label",
      name: "slate",
      image: "/vislang/Icons/SVG/Context/slate.svg",
      download: "/vislang/Icons/SVG/Context/slate.svg",
    },
    "c-000028": {
      id: "c-000028",
      label: "Camera Roll",
      name: "slate",
      image: "/vislang/Icons/SVG/Context/slate.svg",
      download: "/vislang/Icons/SVG/Context/slate.svg",
    },
    "c-000027": {
      id: "c-000027",
      label: "Camera Unit",
      name: "slate",
      image: "/vislang/Icons/SVG/Context/slate.svg",
      download: "/vislang/Icons/SVG/Context/slate.svg",
    },
    "c-000022": {
      id: "c-000022",
      label: "Scene Descriptor",
      name: "slate",
      image: "/vislang/Icons/SVG/Context/slate.svg",
      download: "/vislang/Icons/SVG/Context/slate.svg",
    },
    "c-000023": {
      id: "c-000023",
      label: "Setup",
      name: "slate",
      image: "/vislang/Icons/SVG/Context/slate.svg",
      download: "/vislang/Icons/SVG/Context/slate.svg",
    },
    "c-00002A": {
      id: "c-00002A",
      label: "Shoot Date",
      name: "shoot_day",
      image: "/vislang/Icons/SVG/Context/shoot_day.svg",
      download: "/vislang/Icons/SVG/Context/shoot_day.svg",
    },
    "c-00002B": {
      id: "c-00002B",
      label: "Shoot Day",
      name: "shoot_day",
      image: "/vislang/Icons/SVG/Context/shoot_day.svg",
      download: "/vislang/Icons/SVG/Context/shoot_day.svg",
    },
    "c-000358": {
      id: "c-000358",
      label: "Slate Name",
      name: "",
      image: "",
      download: "",
    },
    "c-000025": {
      id: "c-000025",
      label: "Slate UID",
      name: "slate_ID",
      image: "/vislang/Icons/SVG/Context/slate_ID.svg",
      download: "/vislang/Icons/SVG/Context/slate_ID.svg",
    },
    "c-000029": {
      id: "c-000029",
      label: "Sound Roll",
      name: "slate",
      image: "/vislang/Icons/SVG/Context/slate.svg",
      download: "/vislang/Icons/SVG/Context/slate.svg",
    },
    "c-000024": {
      id: "c-000024",
      label: "Take",
      name: "slate",
      image: "/vislang/Icons/SVG/Context/slate.svg",
      download: "/vislang/Icons/SVG/Context/slate.svg",
    },
    "c-000017": {
      id: "c-000017",
      label: "Slugline",
      name: "narrative_scene",
      image: "/vislang/Icons/SVG/Context/narrative_scene.svg",
      download: "/vislang/Icons/SVG/Context/narrative_scene.svg",
    },
    "s-Participant": {
      id: "s-Participant",
      label: "Participant",
      name: "",
      image: "",
      download: "",
    },
    "c-00004F": {
      id: "c-00004F",
      label: "Participant",
      name: "participant",
      image: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Participant.svg",
      download: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Participant.svg",
    },
    "c-000058": {
      id: "c-000058",
      label: "Participant Functional Class",
      name: "",
      image: "",
      download: "",
    },
    "c-000374": {
      id: "c-000374",
      label: "Archive",
      name: "organization",
      image: "/vislang/Icons/SVG/Participants/organization.svg",
      download: "/vislang/Icons/SVG/Participants/organization.svg",
    },
    "c-000396": {
      id: "c-000396",
      label: "Art Department",
      name: "organization",
      image: "/vislang/Icons/SVG/Participants/organization.svg",
      download: "/vislang/Icons/SVG/Participants/organization.svg",
    },
    "c-000370": {
      id: "c-000370",
      label: "Camera Department",
      name: "organization",
      image: "/vislang/Icons/SVG/Participants/organization.svg",
      download: "/vislang/Icons/SVG/Participants/organization.svg",
    },
    "c-00036E": {
      id: "c-00036E",
      label: "Camera Unit",
      name: "organization",
      image: "/vislang/Icons/SVG/Participants/organization.svg",
      download: "/vislang/Icons/SVG/Participants/organization.svg",
    },
    "c-000371": {
      id: "c-000371",
      label: "Costume Department",
      name: "organization",
      image: "/vislang/Icons/SVG/Participants/organization.svg",
      download: "/vislang/Icons/SVG/Participants/organization.svg",
    },
    "c-000372": {
      id: "c-000372",
      label: "Editorial Department",
      name: "organization",
      image: "/vislang/Icons/SVG/Participants/organization.svg",
      download: "/vislang/Icons/SVG/Participants/organization.svg",
    },
    "c-000055": {
      id: "c-000055",
      label: "Encoding",
      name: "service",
      image: "/vislang/Icons/SVG/Participants/service.svg",
      download: "/vislang/Icons/SVG/Participants/service.svg",
    },
    "c-000059": {
      id: "c-000059",
      label: "Job Title",
      name: "",
      image: "",
      download: "",
    },
    "c-000362": {
      id: "c-000362",
      label: "Actor",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-00036C": {
      id: "c-00036C",
      label: "Casting Director",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-000367": {
      id: "c-000367",
      label: "Composer",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-00035E": {
      id: "c-00035E",
      label: "Director",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-000366": {
      id: "c-000366",
      label: "Director of Photography",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-000365": {
      id: "c-000365",
      label: "Editor",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-00035F": {
      id: "c-00035F",
      label: "First Assistant Director",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-000360": {
      id: "c-000360",
      label: "Producer",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-00036A": {
      id: "c-00036A",
      label: "Production Designer",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-000364": {
      id: "c-000364",
      label: "Screenwriter",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-00036B": {
      id: "c-00036B",
      label: "Set Designer",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-000361": {
      id: "c-000361",
      label: "Showrunner",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-000368": {
      id: "c-000368",
      label: "Sound Mixer",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-00036D": {
      id: "c-00036D",
      label: "Stunt Coordinator",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-000369": {
      id: "c-000369",
      label: "VFX Supervisor",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-000363": {
      id: "c-000363",
      label: "Voice Actor",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-000373": {
      id: "c-000373",
      label: "Production Office",
      name: "organization",
      image: "/vislang/Icons/SVG/Participants/organization.svg",
      download: "/vislang/Icons/SVG/Participants/organization.svg",
    },
    "c-000057": {
      id: "c-000057",
      label: "Rendering",
      name: "service",
      image: "/vislang/Icons/SVG/Participants/service.svg",
      download: "/vislang/Icons/SVG/Participants/service.svg",
    },
    "c-000056": {
      id: "c-000056",
      label: "Transfer",
      name: "service",
      image: "/vislang/Icons/SVG/Participants/service.svg",
      download: "/vislang/Icons/SVG/Participants/service.svg",
    },
    "c-00036F": {
      id: "c-00036F",
      label: "Visual Effects",
      name: "organization",
      image: "/vislang/Icons/SVG/Participants/organization.svg",
      download: "/vislang/Icons/SVG/Participants/organization.svg",
    },
    "c-00005C": {
      id: "c-00005C",
      label: "Participant Group",
      name: "",
      image: "",
      download: "",
    },
    "c-000050": {
      id: "c-000050",
      label: "Paticipant Structural Class",
      name: "",
      image: "",
      download: "",
    },
    "c-000053": {
      id: "c-000053",
      label: "Department",
      name: "organization",
      image: "/vislang/Icons/SVG/Participants/organization.svg",
      download: "/vislang/Icons/SVG/Participants/organization.svg",
    },
    "c-000052": {
      id: "c-000052",
      label: "Organization",
      name: "organization",
      image: "/vislang/Icons/SVG/Participants/organization.svg",
      download: "/vislang/Icons/SVG/Participants/organization.svg",
    },
    "c-000051": {
      id: "c-000051",
      label: "Person",
      name: "person",
      image: "/vislang/Icons/SVG/Participants/person.svg",
      download: "/vislang/Icons/SVG/Participants/person.svg",
    },
    "c-000054": {
      id: "c-000054",
      label: "Service",
      name: "service",
      image: "/vislang/Icons/SVG/Participants/service.svg",
      download: "/vislang/Icons/SVG/Participants/service.svg",
    },
    "c-00005A": {
      id: "c-00005A",
      label: "Role",
      name: "",
      image: "",
      download: "",
    },
    "s-Task": {
      id: "s-Task",
      label: "Task",
      name: "task",
      image: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Task.svg",
      download: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Task.svg",
    },
    "c-00004E": {
      id: "c-00004E",
      label: "Task",
      name: "task",
      image: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Task.svg",
      download: "/vislang/Shapes/SVG/ML_Visual_Language_Shape_Task.svg",
    },
    "c-00035C": {
      id: "c-00035C",
      label: "Task Functional Class",
      name: "",
      image: "",
      download: "",
    },
    "c-00037D": {
      id: "c-00037D",
      label: "Capture Technical Reference Material",
      name: "",
      image: "",
      download: "",
    },
    "c-000387": {
      id: "c-000387",
      label: "Conform Finish",
      name: "",
      image: "",
      download: "",
    },
    "c-000382": {
      id: "c-000382",
      label: "Create Editorial Dailies",
      name: "",
      image: "",
      download: "",
    },
    "c-00037C": {
      id: "c-00037C",
      label: "Create Set",
      name: "",
      image: "",
      download: "",
    },
    "c-000386": {
      id: "c-000386",
      label: "Create Titles",
      name: "",
      image: "",
      download: "",
    },
    "c-000385": {
      id: "c-000385",
      label: "Create Visual Effects",
      name: "",
      image: "",
      download: "",
    },
    "c-00037A": {
      id: "c-00037A",
      label: "Develop Creative Style",
      name: "",
      image: "",
      download: "",
    },
    "c-00038A": {
      id: "c-00038A",
      label: "Distribute",
      name: "",
      image: "",
      download: "",
    },
    "c-000383": {
      id: "c-000383",
      label: "Edit",
      name: "",
      image: "",
      download: "",
    },
    "c-000380": {
      id: "c-000380",
      label: "Maintain Continuity",
      name: "",
      image: "",
      download: "",
    },
    "c-000388": {
      id: "c-000388",
      label: "Master",
      name: "",
      image: "",
      download: "",
    },
    "c-00037E": {
      id: "c-00037E",
      label: "Perform Scene",
      name: "",
      image: "",
      download: "",
    },
    "c-00037B": {
      id: "c-00037B",
      label: "Previsualize",
      name: "",
      image: "",
      download: "",
    },
    "c-00037F": {
      id: "c-00037F",
      label: "Shoot",
      name: "",
      image: "",
      download: "",
    },
    "c-000379": {
      id: "c-000379",
      label: "Write Script",
      name: "",
      image: "",
      download: "",
    },
    "c-00035D": {
      id: "c-00035D",
      label: "Task Group",
      name: "",
      image: "",
      download: "",
    },
    "s-Utility": {
      id: "s-Utility",
      label: "Utility",
      name: "",
      image: "",
      download: "",
    },
    "c-0003AC": {
      id: "c-0003AC",
      label: "Identifier Scope",
      name: "",
      image: "",
      download: "",
    },
    "c-0003CE": {
      id: "c-0003CE",
      label: "Asset Manager",
      name: "asset_manager",
      image: "/vislang/1.2/SVG/asset_manager.svg",
      download: "/vislang/1.2/SVG/asset_manager.svg",
    },
    "c-0003C4": {
      id: "c-0003C4",
      label: "Capture Device",
      name: "capture_service",
      image: "/vislang/1.2/SVG/capture_service.svg",
      download: "/vislang/1.2/SVG/capture_service.svg",
    },
    "c-0003C3": {
      id: "c-0003C3",
      label: "Encoder",
      name: "encode",
      image: "/vislang/1.2/SVG/encode.svg",
      download: "/vislang/1.2/SVG/encode.svg",
    },
    "c-0003CF": {
      id: "c-0003CF",
      label: "Head Mounted Display",
      name: "head_mounted_display",
      image: "/vislang/1.2/SVG/head_mounted_display.svg",
      download: "/vislang/1.2/SVG/head_mounted_display.svg",
    },
    "c-0003C9": {
      id: "c-0003C9",
      label: "LED Display",
      name: "led_display",
      image: "/vislang/1.2/SVG/led_display.svg",
      download: "/vislang/1.2/SVG/led_display.svg",
    },
    "c-0003C8": {
      id: "c-0003C8",
      label: "LED Lighting",
      name: "led_lighting",
      image: "/vislang/1.2/SVG/led_lighting.svg",
      download: "/vislang/1.2/SVG/led_lighting.svg",
    },
    "c-0003CD": {
      id: "c-0003CD",
      label: "LIDAR",
      name: "lidar",
      image: "/vislang/1.2/SVG/lidar.svg",
      download: "/vislang/1.2/SVG/lidar.svg",
    },
    "c-0003CC": {
      id: "c-0003CC",
      label: "Mobile Device",
      name: "mobile_device",
      image: "/vislang/1.2/SVG/mobile_device.svg",
      download: "/vislang/1.2/SVG/mobile_device.svg",
    },
    "c-0003CB": {
      id: "c-0003CB",
      label: "Motion Capture",
      name: "motion_capture",
      image: "/vislang/1.2/SVG/motion_capture.svg",
      download: "/vislang/1.2/SVG/motion_capture.svg",
    },
    "c-0003CA": {
      id: "c-0003CA",
      label: "Motion Control",
      name: "motion_control",
      image: "/vislang/1.2/SVG/motion_control.svg",
      download: "/vislang/1.2/SVG/motion_control.svg",
    },
    "c-0003D2": {
      id: "c-0003D2",
      label: "Network Firewall",
      name: "firewall",
      image: "/vislang/1.2/SVG/firewall.svg",
      download: "/vislang/1.2/SVG/firewall.svg",
    },
    "c-0003D3": {
      id: "c-0003D3",
      label: "Real-time",
      name: "real_time",
      image: "/vislang/1.2/SVG/real_time.svg",
      download: "/vislang/1.2/SVG/real_time.svg",
    },
    "c-0003D0": {
      id: "c-0003D0",
      label: "Renderer",
      name: "renderer",
      image: "/vislang/1.2/SVG/renderer.svg",
      download: "/vislang/1.2/SVG/renderer.svg",
    },
    "c-0003D4": {
      id: "c-0003D4",
      label: "Time Critical",
      name: "time_critical",
      image: "/vislang/1.2/SVG/time_critical.svg",
      download: "/vislang/1.2/SVG/time_critical.svg",
    },
    "c-0003C6": {
      id: "c-0003C6",
      label: "Video Router",
      name: "video_router_fill",
      image: "/vislang/1.2/SVG/video_router_fill.svg",
      download: "/vislang/1.2/SVG/video_router_fill.svg",
    },
    "c-0003C5": {
      id: "c-0003C5",
      label: "Video Switcher",
      name: "video_switcher",
      image: "/vislang/1.2/SVG/video_switcher.svg",
      download: "/vislang/1.2/SVG/video_switcher.svg",
    },
  },
};
