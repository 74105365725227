const config = require("../../config");
const { isEmpty } = require("lodash");

const getActiveDocumentationTypes = ({ filterByActive = true } = {}) => {
  let types = config.documentation.types;
  if (filterByActive) {
    types = types.filter((type) => !isEmpty(type.sources));
  }
  return types;
};

const getDocumentationTitles = ({ filterByActive = true } = {}) =>
  getActiveDocumentationTypes({ filterByActive }).map((type) => type.title);

const getAllDocumentationSources = () =>
  getActiveDocumentationTypes({ filterByActive: true }).reduce((obj, type) => {
    Object.keys(type.sources).forEach((key, index) => {
      obj[key] = type.sources[key];
    });
    return obj;
  }, {});

const getAllDocumentationSourceKeys = () =>
  getActiveDocumentationTypes({ filterByActive: true })
    .map((type) => Object.keys(type.sources).map((key) => key))
    .filter(Boolean)
    .flat();

const getAllVersions = (documents) => {
  let versions = [...new Set(documents.map(({ basePath }) => basePath))]
    .map((basePath) => basePath?.split("/")[1])
    .filter(Boolean);

  !isEmpty(versions)
    ? versions.unshift(`v${Number(versions[0].substring(1)) + 1}`)
    : versions.unshift("v1");

  return versions;
};

const getVersion = (basePath, versions) => {
  return basePath.split("/")[1] || versions[0];
};

const getTypeVersionsMap = (documents) => {
  const typeVersionsMap = documents.reduce((map, document) => {
    const type = document.basePath.split("/")[0];
    const version = document.basePath.split("/")[1] || "";

    map[type] = map[type] ? [...map[type], version] : [version];

    return map;
  }, {});

  for (const key of Object.keys(typeVersionsMap)) {


    typeVersionsMap[key] = [
      ...new Set(typeVersionsMap[key].map((version) => version)),
    ].filter(Boolean);

    // added to hopefully hack versions to work today
    if ( key == 'ontology') {
      typeVersionsMap[key] = ['v2.5', 'v2.0', 'v1.0'];
    } else if (!isEmpty(typeVersionsMap[key])) {
      if (typeVersionsMap[key][0].includes(".")) {
        // if the version number includes a minor version
        const minorNumberIndex = typeVersionsMap[key][0].indexOf(".") + 1;
        const minorNumberIncrement =
          Number(typeVersionsMap[key][0].substring(minorNumberIndex)) + 1;

        typeVersionsMap[key].unshift(
          typeVersionsMap[key][0].substring(0, minorNumberIndex) +
            minorNumberIncrement +
            typeVersionsMap[key][0].substring(
              minorNumberIndex + Number(String(minorNumberIncrement).length)
            )
        );
      } else {
        typeVersionsMap[key].unshift(
          `v${Number(typeVersionsMap[key][0].substring(1)) + 1}`
        );
      }
    } else {
      // if the version number is only major version number
      typeVersionsMap[key].unshift("v1");
    }

    // !isEmpty(typeVersionsMap[key])
    //   ? typeVersionsMap[key].unshift(
    //       `v${Number(typeVersionsMap[key][0].substring(1)) + 1}`
    //     )
    //   : typeVersionsMap[key].unshift("v1");
  }

  return typeVersionsMap;
};

const getAllVersionDocumentsMap = (versions, documents) => {
  return documents.reduce((map, document) => {
    const version = document.basePath.split("/")[1] || versions[0];
    map[version] = map[version] ? [...map[version], document] : [document];
    return map;
  }, {});
};

const getAssociatedDocuments = (basePath, documents) => {
  return documents.filter((document) => document.basePath === basePath);
};

module.exports = {
  getActiveDocumentationTypes,
  getDocumentationTitles,
  getAllDocumentationSources,
  getAllDocumentationSourceKeys,
  getAllVersions,
  getTypeVersionsMap,
  getVersion,
  getAllVersionDocumentsMap,
  getAssociatedDocuments,
};
