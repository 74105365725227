import React, { forwardRef } from "react";
// import useDimensions from "../hooks/useDimensions";
import { Flex, Spacer, Box, Heading, useMediaQuery } from "@chakra-ui/react";
import Image from "../components/elements/Image";
import Link from "../components/elements/Link";
import NavBar from "./NavBar";
// import Hero from "../components/Hero";
import SiteTitle from "./SiteTitle";

const Header = forwardRef(
  ({ appTitle, logos, pageTitle, pageSubtitle, backgroundImage }, ref) => {
    // const [navbarRef, { height }] = useDimensions();
    const [showSiteTitle] = useMediaQuery(["(min-width: 600px)"]);

    return (
      <Box as="header" position="relative">
        <Box
          // ref={navbarRef}
          // position="absolute"
          // top="0"
          // left="0"
          // zIndex="1"
          // w="100%"
          p="20px 30px"
          bgColor="blackAlpha.900"
        >
          <Flex flexWrap="wrap" alignItems="center">
            <Link href="https://www.movielabs.com" display="flex" alignItems="center" target="_blank">
              {appTitle && !logos.light && <Heading>{appTitle}</Heading>}
              {logos.light && (
                <Image
                  image={logos.light}
                  alt="movielabs logo"
                  width="150px"
                  style={{ minWidth: "150px" }}
                />
              )}

            </Link>
              <Link href="/" display="flex" alignItems="center">
                  {showSiteTitle && <SiteTitle divider />}
              </Link>
            <Spacer />
            <NavBar />
          </Flex>
        </Box>
        {/* <Box h="80.59375px" /> */}
        {/* <Hero
        title={pageTitle}
        subtitle={pageSubtitle}
        backgroundImage={backgroundImage}
      /> */}
      </Box>
    );
  }
);

export default Header;
