import React from "react";
import { Box, Heading, Divider } from "@chakra-ui/react";

function SiteTitle({ divider = false, ...props }) {
  return (
    <Box display="flex" alignItems="center">
      {divider && (
        <Divider orientation="vertical" opacity="0.2" h="55px" ml={5} />
      )}
      <Heading
        as="h1"
        color="white"
        // fontSize="30.4px"
        fontSize="25px"
        fontWeight="900"
        textTransform="uppercase"
        lineHeight="0.8"
        letterSpacing="-1px"
        ml={5}
        {...props}
      >
        <Box as="span">Media Creation</Box>
        <br />{" "}
        <Box as="span" fontSize="0.55em">
          Documentation &amp; Resources
        </Box>
      </Heading>
    </Box>
  );
}

export default SiteTitle;
