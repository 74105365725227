import config from "../../config";
import React from "react";
import { Link } from "gatsby";
// import { isEmpty } from "lodash";
import {
  Box,
  Text,
  List,
  ListItem,
  Image,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { getActiveDocumentationTypes } from "../lib/documentation";

let activeDocumentationTypes = getActiveDocumentationTypes();

const gutter = 20;
const iconSize = 50;
const closedWidth = gutter * 2 + iconSize;

export default function NavSidebar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const closedSidebarBg = useColorModeValue("gray.50", "blackAlpha.50");
  const openSidebarBg = useColorModeValue("white", "black");

  return (
    <Box>
      <Box
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        opacity={isOpen ? 1 : 0}
        w="100%"
        h="100%"
        bgColor="blackAlpha.800"
        transform={isOpen ? "translateX(0)" : "translateX(100%)"}
        transition="opacity 100ms ease-in"
      />
      <Box w={`${closedWidth}px`} h="100%">
        <Box
          position="absolute"
          top={0}
          left={0}
          zIndex={2}
          maxW={isOpen ? "350px" : `${closedWidth}px`}
          h="100%"
          py={8}
          bgColor={isOpen ? openSidebarBg : closedSidebarBg}
          borderRightWidth={1}
          overflow="hidden"
          transition="max-width 200ms ease, transform 200ms ease, background-color 300ms ease"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
        >
          <List spacing={8}>
            {activeDocumentationTypes.map((documentationType, index) => (
              <ListItem key={index} px={`${gutter}px`}>
                <Box
                  as={Link}
                  to={documentationType.path}
                  fontWeight="bold"
                  display="flex"
                  alignItems="center"
                  activeClassName="navsidebar-item--active"
                  partiallyActive
                  sx={{
                    "&:hover, &.navsidebar-item--active": {
                      color: "blue.600",
                      ".navsidebar-item__icon": {
                        bgColor: "blue.600",
                        img: {
                          WebkitFilter: "brightness(0) invert(1)",
                          filter: "brightness(0) invert(1)",
                        },
                      },
                    },
                  }}
                >
                  <Box
                    className="navsidebar-item__icon"
                    flexShrink={0}
                    w={`${iconSize}px`}
                    display="inline-flex"
                    justifyContent="center"
                    alignItems="center"
                    boxSize={`${iconSize}px`}
                    mr={5}
                    borderRadius={5}
                  >
                    <Image
                      src={documentationType.icon}
                      alt="Visual Language Icon"
                      boxSize={`${iconSize / 2}px`}
                      fit="contain"
                      sx={{
                        WebkitFilter: "grayscale(1)",
                        filter: "grayscale(1)",
                      }}
                    />
                  </Box>
                  <Text whiteSpace="nowrap">{documentationType.title}</Text>
                </Box>
              </ListItem>
            ))}

            <ListItem px={`${gutter}px`}>
              <Box
                as={Link}
                to={config.vocabulary.types[0].path}
                fontWeight="bold"
                display="flex"
                alignItems="center"
                activeClassName="navsidebar-item--active"
                sx={{
                  "&:hover, &.navsidebar-item--active": {
                    color: "blue.600",
                    ".navsidebar-item__icon": {
                      bgColor: "blue.600",
                      img: {
                        WebkitFilter: "brightness(0) invert(1)",
                        filter: "brightness(0) invert(1)",
                      },
                    },
                  },
                }}
              >
                <Box
                  className="navsidebar-item__icon"
                  flexShrink={0}
                  w={`${iconSize}px`}
                  display="inline-flex"
                  justifyContent="center"
                  alignItems="center"
                  boxSize={`${iconSize}px`}
                  mr={5}
                  borderRadius={5}
                >
                  <Image
                    src={config.vocabulary.types[0].icon}
                    alt="Visual Language Icon"
                    boxSize={`${iconSize / 2}px`}
                    fit="contain"
                    sx={{
                      WebkitFilter: "grayscale(1)",
                      filter: "grayscale(1)",
                    }}
                  />
                </Box>
                <Text>{config.vocabulary.types[0].title}</Text>
              </Box>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
}
