import React from "react";
import { Helmet } from "react-helmet";
import { omit } from "lodash";

function Head({ lang, appTitle, pageTitle, meta = {} }) {
  const title = pageTitle ? `${pageTitle} | ${appTitle}` : appTitle;

  const description =
    meta?.description && meta?.description?.trim() !== ""
      ? meta.description
      : "MovieLabs is dedicated to media creation documentation. Provided for those looking to implement parts of the 2030 Vision for the future of Media Creation.";

  const metaArr = Object.keys(
    omit(meta, ["description", "ogTitle", "ogDescription", "ogType"])
  ).map((key) => ({
    name: key,
    content: meta[key],
  }));

  return (
    <Helmet
      htmlAttributes={{
        lang: lang || `en_US`,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: meta?.ogTitle || title,
        },
        {
          property: `og:description`,
          content: meta?.ogDescription || description,
        },
        {
          property: `og:type`,
          content: meta?.ogType || `website`,
        },
        {
          property: `og:locale`,
          content: lang || `en_US`,
        },
      ].concat(metaArr)}
    >
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Roboto+Mono:wght@300;400;500&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
}

export default Head;
