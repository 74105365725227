import React from "react";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import { Box } from "@chakra-ui/react";
import Head from "./Head";
import Header from "./Header";
import CookieConsent from "../components/CookieConsent";
// import useDimensions from "../hooks/useDimensions";
import NavSidebar from "./NavSidebar";

export default function Layout({
  children,
  pageTitle,
  pageSubtitle,
  meta,
  backgroundImage,
}) {
  const { pathname } = useLocation();
  const excludedNavSidebarRoutes = ["/", "/docs/"];

  const { site, logoLight, logoDark } = useStaticQuery(
    graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
        logoLight: file(
          relativePath: { eq: "logo_website_gradient_dark.svg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
          extension
          publicURL
        }
        logoDark: file(relativePath: { eq: "logo-dark.svg" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
          extension
          publicURL
        }
      }
    `
  );

  const appTitle = site.siteMetadata.title;

  return (
    <>
      <Head appTitle={appTitle} pageTitle={pageTitle} meta={meta} />
      <Box display="flex" flexDirection="column" h="100vh" overflow="hidden">
        <Header
          appTitle={appTitle}
          logos={{ light: logoLight, dark: logoDark }}
          pageTitle={pageTitle}
          pageSubtitle={pageSubtitle}
          backgroundImage={backgroundImage}
        />
        <Box
          as="main"
          position="relative"
          flex={1}
          display="flex"
          h="full"
          overflow="hidden"
        >
          {!excludedNavSidebarRoutes.includes(pathname) && <NavSidebar />}
          <Box
            flex={1}
            h="full"
            overflow={
              !excludedNavSidebarRoutes.includes(pathname) ? "scroll" : "auto"
            }
          >
            {/* {typeof children === "function"
              ? children({ height: 0 })
              : children} */}
            {children}
          </Box>
        </Box>
      </Box>
      <CookieConsent />
    </>
  );
}
