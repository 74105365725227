import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";

const unsupportedExtentions = ["gif", "svg"];

const Image = ({ className, image, fallbackImage, alt, ...props }) => {
  const handleError = (event) => {
    const fallbackImageSrc =
      !!fallbackImage && !!fallbackImage.childImageSharp
        ? getSrc(fallbackImage)
        : fallbackImage;

    if (fallbackImageSrc) {
      event.target.src = fallbackImageSrc;
    }
  };

  if (!!image && !!image.childImageSharp) {
    const imageData = getImage(image);

    return (
      <GatsbyImage
        {...props}
        className={className}
        image={imageData}
        alt={alt}
      />
    );
  }

  // svg support
  if (
    !!image &&
    !!image.publicURL &&
    unsupportedExtentions.includes(image.extension)
  ) {
    return (
      <div className={className}>
        <img {...props} src={image.publicURL} alt={alt} onError={handleError} />
      </div>
    );
  }

  if (!!image && typeof image === "string") {
    return (
      <div className={className}>
        <img {...props} src={image} alt={alt} onError={handleError} />
      </div>
    );
  }

  // if (!data?.placeholderImage?.childImageSharp) {
  //   return <div>Picture not found</div>
  // }

  return null;
};

Image.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  fallbackImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  alt: PropTypes.string,
  //   imageInfo: PropTypes.shape({
  //     childImageSharp: PropTypes.object,
  //     image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  //     alt: PropTypes.string,
  //     extension: PropTypes.string,
  //     publicURL: PropTypes.string,
  //     style: PropTypes.object,
  //   }).isRequired,
};

export default Image;
