import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import Cookies from "js-cookie";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import {
  Box,
  Flex,
  Text,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
} from "@chakra-ui/react";
import Link from "./elements/Link";

function CookieConsent() {
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const cookie = Cookies.get("ml-cookie-consent");
    if (!cookie) onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConsent = (consent) => {
    Cookies.set("ml-cookie-consent", consent, { expires: 30 });
    if (consent) {
      initializeAndTrack(location);
    }
    onClose();
  };

  return (
    <Drawer
      placement="bottom"
      onClose={() => handleConsent(true)}
      isOpen={isOpen}
    >
      <DrawerOverlay />
      <DrawerContent pb={30}>
        <DrawerHeader borderBottomWidth="1px">Cookie Consent</DrawerHeader>
        <DrawerBody>
          <Flex
            flexDirection={["column", "row"]}
            flexWrap="wrap"
            alignItems="flex-end"
          >
            <Box maxW="750px" mt={3} mb={6} mr={10}>
              <Text mb={3}>
                We and selected third parties use cookies or similar
                technologies as specified in the{" "}
                <Link to="/privacy-policy/" textDecoration="underline">
                  privacy policy
                </Link>
                .
              </Text>
              <Text>
                You can consent to the use of such technologies by closing this
                notice, by interacting with any link or button outside of this
                notice or by continuing to browse otherwise.
              </Text>
            </Box>
            <ButtonGroup mb={6} ml="auto">
              <Button
                colorScheme="blue"
                variant="outline"
                onClick={() => handleConsent(false)}
              >
                Deny
              </Button>
              <Button colorScheme="blue" onClick={() => handleConsent(true)}>
                Accept
              </Button>
            </ButtonGroup>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default CookieConsent;
