import React, { forwardRef } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Link as ChakraLink } from "@chakra-ui/react";

const Link = forwardRef(({ children, to, ...props }, ref) => {
  if (to) {
    return (
      <ChakraLink ref={ref} {...props} to={to} as={GatsbyLink}>
        {children}
      </ChakraLink>
    );
  }

  return (
    <ChakraLink {...props} rel="nofollow noreferrer">
      {children}
    </ChakraLink>
  );
});

export default Link;
